.logo {
  padding-top: 26px;
}

.logo a {
  font-weight: 500;
  font-size: 24px;
  text-transform: capitalize;
  text-decoration: none;
}

.logo a:hover {
  color: #000;
}

.nav a {
  color: #000;
}

.menu {
  padding-top: 20px;
}

.menu a {
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
}

.nav > li > a:hover {
  background-color: #fff;
  color: #1a98eb;
}
.nav > li > a:focus {
  background-color: #fff;
  color: #1a98eb;
}
.content {
  min-height: calc(100vh - 92px - 8rem);
}

li > a.active {
  color: #1a98eb;
}

@media only screen and (max-width: 767px) {
  .btn-menu {
    font-size: 24px;
    margin-top: -62px;
  }
}
