.img-container img {
  width: 100%;
  border: 1px solid #1a98eb;
  padding: 10px;
}

h1 {
  font-weight: 700;
}

h2 {
  border-bottom: 1px solid #1a98eb;
  line-height: 2em;
}

label {
  color: #1a98eb;
}

label a {
  color: #1a98eb;
}

.project-desc {
  clear: both;
  padding-top: 20px;
  text-align: justify;
}

.row {
  padding-top: 10px;
}

.project {
  padding-top: 10px;
}

.social_link {
  display: flex;
  padding-top: 15px;
}

.social_link i {
  font-size: 20px;
  padding: 8px;
}

.social_link a {
  width: 35px;
  height: 35px;
  display: block;
  background-color: #1a98eb;
  margin-right: 18px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  transition: all 0.2s;
}

.leafit-logo {
  width: 52px;
}

.project-detail {
  clear: both;
  padding-top: 10px;
}

.scikey-logo {
  width: 135px;
}

.social_link ul {
  display: block;
  margin: 0;
  padding: 0;
}

.social_link li {
  display: block;
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .project-detail {
    clear: left;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .img-container {
    padding-top: 25px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .project-detail {
    width: 75%;
  }
}
