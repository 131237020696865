.more-desc {
  display: flex;
  padding-top: 50px;
}

.more-desc ul {
  display: block;
  margin: 0;
  padding: 0;
}

.more-desc li {
  display: flex;
  padding-bottom: 10px;
}

.about-desc {
  text-align: justify;
}

label {
  width: 130px;
}

.my-skills {
  padding-top: 10px;
}
.skills li {
  display: flex;
  padding-bottom: 10px;
}

.skills ul {
  margin: 0;
  padding: 0;
}

.about-desc p {
  margin-top: 2.5rem;
  margin-bottom: 0px;
}

@media only screen and (min-width: 767px) {
  .about-desc {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .my-skills .skills span {
    padding-left: 15px;
  }

  .other_skill {
    padding-left: 0px !important;
  }
}
