.experience,
.edu-desc {
  padding-top: 20px;
}

.experience label,
.edu-desc label {
  width: unset;
}

.experince-year {
  text-align: right;
}

.experience span,
.edu-desc span {
  font-weight: 600;
}

.work-desc {
  padding-top: 10px;
  text-align: justify;
}
